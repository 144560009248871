import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faStopwatch,
	faTableTennis,
	faChartBar,
	faSync,
} from "@fortawesome/free-solid-svg-icons";

const features = [
	{
		icon: faStopwatch,
		title: "Apple Watch Native",
		description:
			"Designed specifically for Apple Watch, allowing you to track scores without touching your phone",
		highlight: "Seamless Integration",
	},
	{
		icon: faTableTennis,
		title: "Multi-Sport Support",
		description:
			"Support for Tennis, Padel, Pickleball, Badminton, Ping Pong and Squash with sport-specific scoring rules",
		highlight: "Versatile Tracking",
	},
	{
		icon: faChartBar,
		title: "Match Statistics",
		description:
			"Track your performance with detailed match statistics and comprehensive match history",
		highlight: "Deep Analytics",
	},
	{
		icon: faSync,
		title: "Real-Time Sync",
		description:
			"Seamlessly sync your match data between your Apple Watch and iPhone",
		highlight: "Instant Updates",
	},
];

const FeaturesSection = ({ isDark }) => {
	return (
		<section
			id="features"
			className={`py-20 ${
				isDark ? "bg-gradient-to-b from-gray-900 to-black" : "bg-white"
			}`}
		>
			<div className="container mx-auto px-4 md:px-6">
				<div className="text-center mb-20" data-aos="fade-up">
					<h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
						Powerful Features
					</h2>
					<p
						className={`text-lg max-w-2xl mx-auto ${
							isDark ? "text-gray-400" : "text-gray-600"
						}`}
					>
						Everything you need to enhance your game tracking experience
					</p>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-20 pb-4">
					{features.map((feature, index) => (
						<div
							key={index}
							className="relative group"
							data-aos="fade-up"
							data-aos-delay={index * 100}
						>
							<div
								className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl opacity-10 
								group-hover:opacity-20 transition duration-300"
								style={{ zIndex: 0 }}
							></div>

							<div
								className={`relative p-8 rounded-2xl shadow-xl hover:shadow-2xl transition duration-300 h-full ${
									isDark ? "bg-gray-800" : "bg-gray-100"
								}`}
							>
								<div
									className="w-16 h-16 rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center mb-6 
									transform transition-transform duration-300 hover:scale-110"
								>
									<FontAwesomeIcon
										icon={feature.icon}
										className="text-white text-2xl"
									/>
								</div>

								<span className="text-blue-400 font-medium text-sm uppercase tracking-wider mb-2 block">
									{feature.highlight}
								</span>

								<h3
									className={`text-2xl font-bold mb-4 ${
										isDark ? "text-white" : "text-gray-900"
									}`}
								>
									{feature.title}
								</h3>

								<p
									className={`leading-relaxed ${
										isDark ? "text-gray-400" : "text-gray-600"
									}`}
								>
									{feature.description}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default FeaturesSection;
