import heroGif from "../../assets/hero2.gif";
import { APP_STORE_URL } from "../../constants/urls";

export default function HeroSection({ isDark }) {
	return (
		<section
			id="hero"
			className={`pt-20 pb-32 ${isDark ? "bg-gray-900" : "bg-white"}`}
		>
			<div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
				<div className="lg:w-1/2" data-aos="fade-right">
					<h1
						className={`text-5xl lg:text-6xl font-bold font-display leading-tight ${
							isDark ? "text-white" : "text-gray-900"
						}`}
					>
						Never Miss a{" "}
						<span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
							Point
						</span>{" "}
						with{" "}
						<span className="relative inline-block">
							Racketly
							<div className="absolute -bottom-2 left-0 right-0 h-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full"></div>
						</span>
					</h1>
					<p
						className={`mt-6 text-xl leading-relaxed ${
							isDark ? "text-gray-300" : "text-gray-600"
						}`}
					>
						Track your{" "}
						<span
							className={`font-semibold ${
								isDark ? "text-gray-100" : "text-gray-800"
							}`}
						>
							tennis
						</span>
						,{" "}
						<span
							className={`font-semibold ${
								isDark ? "text-gray-100" : "text-gray-800"
							}`}
						>
							padel
						</span>
						,{" "}
						<span
							className={`font-semibold ${
								isDark ? "text-gray-100" : "text-gray-800"
							}`}
						>
							pickleball
						</span>
						, and{" "}
						<span
							className={`font-semibold ${
								isDark ? "text-gray-100" : "text-gray-800"
							}`}
						>
							badminton
						</span>{" "}
						matches effortlessly from your Apple Watch. Focus on your game while
						Racketly takes care of the score.
					</p>
					<div className="mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
						<a
							href={APP_STORE_URL}
							target="_blank"
							rel="noopener noreferrer"
							className="bg-black text-white px-8 py-3 rounded-lg hover:bg-gray-800 transition flex items-center justify-center"
						>
							<svg
								className="w-6 h-6 mr-2"
								viewBox="0 0 24 24"
								fill="currentColor"
							>
								<path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z" />
							</svg>
							Download on App Store
						</a>
						<a
							href="#features"
							className={`border ${
								isDark
									? "border-gray-700 hover:bg-gray-800"
									: "border-gray-300 hover:bg-gray-50"
							} px-8 py-3 rounded-lg transition text-center ${
								isDark ? "text-gray-300" : "text-gray-600"
							}`}
						>
							Learn More
						</a>
					</div>
					<div className="mt-8 flex items-center space-x-4">
						<p
							className={`text-sm ${
								isDark ? "text-gray-400" : "text-gray-600"
							}`}
						>
							Joined by <span className="font-semibold">5,000+</span> players
							worldwide
						</p>
					</div>
				</div>

				<div className="lg:w-1/2 mt-12 lg:mt-10" data-aos="fade-left">
					<div className="relative max-w-[280px] mx-auto">
						<div className="absolute inset-0 bg-gradient-to-tr from-blue-500/30 to-purple-500/30 rounded-[3rem] blur-xl"></div>

						<div className="relative bg-gray-900 rounded-[3rem] p-3 shadow-2xl">
							<div className="relative overflow-hidden rounded-[2.5rem]">
								<img
									src={heroGif}
									alt="Racketly App Demo"
									className="w-full aspect-[9/19.5] object-cover object-top"
									style={{
										height: "calc(100% + 40px)",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
