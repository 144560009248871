import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./components/Navbar";
import HeroSection from "./components/sections/HeroSection";
import FeaturesSection from "./components/sections/FeaturesSection";
import SportsSection from "./components/sections/SportsSection";
import ScreenshotsSection from "./components/sections/ScreenshotsSection/ScreenshotsSection";
import PricingSection from "./components/sections/PricingSection";
import CTASection from "./components/sections/CTASection";
import FooterSection from "./components/sections/FooterSection";

function App() {
	useEffect(() => {
		AOS.init({
			duration: 1000,
			once: true,
			easing: "ease-in-out",
		});
	}, []);

	return (
		<div className="min-h-screen">
			<Navbar />
			<main>
				<HeroSection isDark={false} />
				<FeaturesSection isDark={true} />
				<SportsSection isDark={false} />
				<ScreenshotsSection isDark={true} />
				<CTASection isDark={false} />
			</main>
			<FooterSection isDark={true} />
		</div>
	);
}

export default App;
